<script setup lang="ts">
interface DefaultLayoutProps {
    withReservationForm?: boolean
    offsetReservationForm?: boolean
}

defineProps<DefaultLayoutProps>()

const { category, departureAgency, returnAgency, departureTime, returnTime } = useReservationParams()
</script>

<template>
    <div class="layout-wrapper">
        <header :class="$style.header">
            <VTopBar />
            <slot name="header" />
        </header>
        <LazyVReservationForm
            v-if="withReservationForm"
            :class="$style['reservation-form']"
            :offset-top="offsetReservationForm ? 'md' : undefined"
            :category="category"
            :departure-agency="departureAgency"
            :return-agency="returnAgency"
            :departure-time="departureTime"
            :return-time="returnTime"
        />
        <slot />
        <VFooter />
    </div>
</template>

<style lang="scss" module>
.header {
    width: 100%;
}

.reservation-form {
    margin-bottom: var(--spacing-md);
}
</style>
